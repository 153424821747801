<template>
  <div class="contain">
    <div class="left-contain">
      <div class="menus">
        <div v-for="item in tabs" :key="item.type" class="item" :class="{ selected: item.id == options.type }"
          @click="selectTab(item)">
          {{ item.name }}
        </div>
      </div>
      <el-input class="my-el-input" type="search" v-model="options.search" placeholder="输入课程名称搜索"
        prefix-icon="el-icon-search"></el-input>

      <pagination2 ref="list" class="list" :option="options" :allFn="false" url="/ClientCourse/courseList"
        @complete="getCourseData">
        <template v-slot:default="{ tableData, empty }">
          <ul class="list-scroll" v-if="tableData.length > 0">
            <li v-for="item in tableData" :key="item.course_id || item.open_class_id" class="course-item"
              :title="item.name" :class="{
                selected:
                  selectedCourse &&
                  (item.open_class_id
                    ? item.open_class_id == selectedCourse.open_class_id
                    : item.course_id == selectedCourse.course_id),
              }" @click="selectedCourseClick(item)">
              <div class="cover">
                <img :src="item.photo" alt="" />
                <div class="tip" v-if="options.type==2">
                  <span v-if="item.screen_type==2">竖屏</span>
                  <span v-else>横屏</span>
                </div>
                <div class="status" v-if="!item.open_class_id">
                  已{{ item.status == 1 ? '上架' : '下架' }}
                </div>
              </div>
              <div class="info">
                <div class="title">{{ item.name }}</div>
                <div class="time" v-if="item.open_class_id">
                  <template v-if="item.start_time > 0">
                    最近开播：{{
                      item.start_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                    }}
                  </template>
                </div>
                <div v-else class="time">共 {{ item.schedule_num }} 节</div>
              </div>
            </li>
          </ul>
          <div v-if="empty" class="empty-contain">
            <img class="img" src="~@ass/img/1.4.4.3/data-empty.png" alt="" />
            <div class="text">暂无数据</div>
          </div>
        </template>
      </pagination2>
    </div>
    <div class="right-contain" v-if="selectedCourse">
      <div class="course-item course-item2">
        <div class="cover">
          <img :src="selectedCourse.photo" alt="" />
          <div class="tip" v-if="options.type==2">
            <span v-if="selectedCourse.live_mode != 3">
              {{ selectedCourse.live_mode | getlive_mode }}
            </span>
            <span v-if="selectedCourse.screen_type==2">竖屏</span>
            <span v-else>横屏</span>
          </div>
          <div class="status" v-if="!selectedCourse.open_class_id">
            已{{ selectedCourse.status == 1 ? '上架' : '下架' }}
          </div>
        </div>
        <div class="info">
          <div class="title">{{ selectedCourse.name }}</div>
          <div class="time" v-if="selectedCourse.open_class_id">
            <template v-if="selectedCourse.start_time > 0">
              最近开播：{{
                selectedCourse.start_time | formatTimeStamp('yyyy-MM-dd hh:mm')
              }}
            </template>
          </div>
          <div v-else class="time">共 {{ selectedCourse.schedule_num }} 节</div>
        </div>
      </div>
      <div v-if="selectedCourse.open_class_id" class="class-btn">
        <classesBegin :item="selectedCourse" type="4">
          <el-button class="openClass-btn">进入教室</el-button>
        </classesBegin>
        <el-button class="openClass-btn" @click="liveOpenCourseClick(selectedCourse)">
          分享
        </el-button>
        <el-button class="openClass-btn" @click="pushsetingclick(selectedCourse)">
          推送上课通知
        </el-button>
        <el-button class="openClass-btn" @click="teacherAdmin(selectedCourse.open_class_id)">
          聊天室
        </el-button>
      </div>
      <template v-else>
        <div class="menus">
          <div v-for="item in tabs2" :key="item.type" class="item" :class="{ selected: item.id == selectedCourse.type }"
            @click="selectedCourse.type = item.id">
            {{ item.name }}
          </div>
        </div>
        <pagination2 class="list" :option="selectedCourse" :allFn="true" url="/ClientCourse/scheduleList"
          @complete="scheduleCount = arguments[0].scheduleCount || []">
          <template v-slot:default="{ tableData, empty }">
            <ul class="list-scroll" v-if="tableData.length > 0">
              <li class="item" v-for="item in tableData" :key="item.sl_id">
                <div class="t1">{{ item.title }}</div>
                <div class="t2">
                  <template v-if="item.open_class_id > 0">公开课</template>
                  <template v-else>
                    <template v-if="selectedCourse.course_type == 1">
                      {{ item.live_mode == 1 || item.schedule_live_mode == 1 ? '普通模式' : '极速模式' }}
                      <i style="margin: -1px 10px 0">|</i>
                    </template>
                    {{ item.startTime | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                  </template>
                  <i style="margin: -1px 10px 0">|</i>
                  {{ item.uname }}
                </div>
                <div class="t3" v-if="selectedCourse.type == 1">
                  <classesBegin :item="item" :type="item.open_class_id > 0 ? 4 : 3">
                  <!-- <classesBegin :item="item" :type="item.schedule_live_mode == 3 ? 4 : 3"> -->
                    <span class="btn">发起直播</span>
                  </classesBegin>
                  <i style="margin: -1px 10px 0">|</i>
                  <span class="btn"
                    @click="item.open_class_id > 0 ? liveOpenCourseClick(item) : liveAndSmallClassClick(item)">
                    分享
                  </span>
                  <i style="margin: -1px 10px 0">|</i>
                  <span class="btn" @click="item.open_class_id > 0 ? pushsetingclick(item) : notification1(item)">
                    推送上课通知
                  </span>
                </div>
              </li>
            </ul>
            <div v-if="empty" class="empty-contain">
              <img class="img" src="~@ass/img/1.4.4.3/data-empty.png" alt="" />
              <div class="text">暂无数据</div>
            </div>
          </template>
        </pagination2>
      </template>
    </div>
    <div v-else class="empty-contain" style="margin: 210px auto 0">
      <img class="img" src="~@ass/img/1.4.4.3/data-empty.png" alt="" />
      <div class="text">暂无数据</div>
    </div>
    <!--分享课程直播-->
    <invitePop v-if="shareInfos" :live_mode="shareInfos.live_mode" :editData="shareInfos" @close="toggleShareId">
    </invitePop>
    <!-- 分享公开课 -->
    <shareinfo v-if="openClassshareinfo" @update:dialogstatus="openClassshareinfo = null"
      :dialogstatus="!!openClassshareinfo" :shareinfoval="openClassshareinfo"></shareinfo>
    <!-- 直播-公开课推送通知设置 -->
    <push-settings v-if="status2" :pushseting="pushseting" :dialogstatus.sync="status2"></push-settings>

    <!-- 发送上课通知弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="422px" class="sure" :close-on-click-modal="false">
      <span class="font1">
        一般无需手动发送上课通知，系统默认开课前10分钟自动发送；学生未收到时可以选择手动发送，避免重复发送，对学生产生打扰，建议慎重操作手动发送！
      </span>
      <span slot="title">发送通知（{{ sub_count }}人订阅）</span>
      <span slot="footer" class="dialog-footer">
        <el-popover placement="right" width="305" trigger="hover">
          <div>
            <img src="../../assets/img/1.3.3/img_ylsktz.png" alt="" />
          </div>
          <el-button class="fl" style="padding-left: 0" slot="reference" type="text">
            预览效果
          </el-button>
        </el-popover>
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapMutations } = createNamespacedHelpers('OpenClassopenClassLive')
import shareinfo from '@/components/openCourse/shareinfo'
// 上课邀请
import invitePop from '@/components/coursePop/invitePop'
import pushSettings from '@/views/LiveBroadcastManagement/openCourse/components/pushSettings'

export default {
  name: 'home-allCourse',

  components: {
    shareinfo,
    invitePop,
    pushSettings,
  },

  data() {
    return {
      options: {
        search: '',
        type: 1,
        pageSize: 5,
        num: 5,
      },

      courseCount: [],
      scheduleCount: [],

      selectedCourse: null,

      shareInfos: null,
      openClassshareinfo: null,

      status2: false,

      pushseting: {},

      // 订阅人数
      sub_count: 0,
      dialogVisible: false,
    }
  },
  filters: {
    getstatus(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '直播中'
          break
        case 2:
          text = '未直播'
          break
        case 3:
          text = '直播结束'
          break
      }
      return text
    },

    getlive_mode(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '标准直播'
          break
        case 2:
          text = '快直播'
          break
      }
      return text
    },
  },
  computed: {
    // 左侧菜单
    tabs() {
      return [
        {
          name: `直播课(${this.courseCount[0] || 0})`,
          id: 1,
        },
        {
          name: `公开课(${this.courseCount[1] || 0})`,
          id: 2,
        },
        {
          name: `小班课(${this.courseCount[2] || 0})`,
          id: 3,
        },
      ]
    },

    // 右侧菜单 待上课 已结束
    tabs2() {
      return [
        {
          name: `待上课(${this.scheduleCount[0] || 0})`,
          id: 1,
        },
        {
          name: `已结束(${this.scheduleCount[1] || 0})`,
          id: 2,
        },
      ]
    },
  },

  methods: {
    ...mapMutations(['sendToWindowsSizeInOpenClass']),

    // 返回控制台
    teacherAdmin(val) {
      if (!this.is_teacher) {
        this.$root.prompt('该公开课直播老师可进入聊天室！')
        return
      } else {
        this.open_class_id = val
        let url = this.$router.resolve({
          path: '/OpenClass_openClassLive/' + val,
        })
        this.$store.dispatch('open2', { type: 7, url: url.href, id: val })
      }
    },

    // 切换菜单
    selectTab({ id }) {
      this.options.type = id
    },

    // 获取课程数据
    getCourseData({ courseCount = [], data }) {
      this.courseCount = courseCount
      if (data && data[0]) {
        this.selectedCourseClick(data[0])
      } else {
        this.selectedCourseClick('')
      }
    },

    // 选中得课程
    selectedCourseClick(item) {
      if (item) {
        this.selectedCourse = Object.assign({}, item, { type: 1, pageSize: 20 })
        this.is_teacher = item.is_teacher
      } else {
        this.selectedCourse = null
      }
    },

    //直播和小班分享
    liveAndSmallClassClick(item) {
      this.shareInfos = item
    },

    // 显示分享弹窗页面
    toggleShareId() {
      this.shareInfos = null
    },

    // 分享直播
    liveOpenCourseClick(item) {
      this.openClassshareinfo = item
    },

    //推送上课通知
    sure() {
      this.$http({
        url: `/user/sendNotice`,
        data: {
          schedule_id: this.schedule_id,
        },
        callback: () => {
          this.dialogVisible = false
          this.$root.prompt({
            msg: '发送成功！',
            type: 'success',
          })
        },
      })
    },

    notification1(item) {
      this.schedule_id = item.correlation_id
      this.$http({
        url: `/user/sendNotice?schedule_id=${item.correlation_id}`,
        callback: ({ count }) => {
          this.sub_count = count
          this.dialogVisible = true
        },
      })
    },

    //公开课推送通知设置
    pushsetingclick(item) {
      this.pushseting = item
      this.status2 = true
    },
  },
}
</script>
<style lang="scss" scoped>
.contain {
  display: flex;
  height: 100%;
  width: 100%;

  .left-contain {
    width: 370px;
    display: flex;
    flex-flow: column;
    height: 100%;
    border-right: 1px solid #f5f5f5;

    .menus {
      display: flex;
      border-bottom: 1px solid #f5f5f5;

      .item {
        flex: 1;
        font-size: 14px;
        line-height: 43px;
        color: #666666;
        text-align: center;
        cursor: pointer;

        &.selected {
          color: #0aa29b;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            width: 30px;
            height: 2px;
            bottom: 0;
            background: #0aa29b;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
          }
        }
      }
    }

    ::v-deep .my-el-input {
      margin: 26px 20px 10px;

      input {
        width: 330px;
        height: 36px;
        font-size: 14px;
        background: #f5f5f5;
        border-radius: 20px;
      }

      .el-input__icon {
        line-height: 36px;
      }
    }

    .list {
      flex: 1;
      display: flex;
      flex-flow: column;
      overflow: hidden;

      .list-scroll {
        flex: 1;
        overflow-y: auto;
        @extend %scrollAuto;
        .course-item {
          .cover {
            .tip {
              padding: 6px 4px;
              font-size: 12px;
              color: #ffffff;
              line-height: 12px;
              position: absolute;
              top: 0px;
              left: 0px;
              background: rgba(0, 0, 0, 0.59);
              border-radius: 4px;
            }
          }
        }
      }
    }
  }

  .right-contain {
    flex: 1;
    margin-left: 20px;
    display: flex;
    flex-flow: column;
    height: 100%;

    .menus {
      display: flex;
      border-bottom: 1px solid #f5f5f5;

      .item {
        font-size: 14px;
        line-height: 54px;
        color: #666666;
        text-align: center;
        cursor: pointer;
        padding: 0 20px;

        &.selected {
          color: #0aa29b;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            width: 30px;
            height: 2px;
            bottom: 0;
            background: #0aa29b;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
          }
        }
      }
    }

    .class-btn {
      margin-top: 90px;

      .openClass-btn {
        display: block;
        width: 294px;
        height: 42px;
        color: #0aa29b;
        background: none;
        border-radius: 4px;
        margin: 0 auto 20px;
        border: 1px solid #0aa29b;

        &:first-child {
          color: #fff;
          background: #0aa29b;
        }
      }
    }

    .list {
      flex: 1;
      display: flex;
      flex-flow: column;
      overflow: hidden;

      .list-scroll {
        flex: 1;
        overflow-y: auto;

        @extend %scrollAuto;
      }

      .item {
        padding: 20px 0;
        border-top: 1px solid #f5f5f5;

        &:first-child {
          border-top: 0;
        }

        .t1 {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          word-break: break-all;
        }

        .t2 {
          font-size: 12px;
          margin-top: 12px;
          color: #666666;
          display: flex;
          align-items: center;
        }

        .t3 {
          font-size: 12px;
          color: #0aa29b;
          margin-top: 20px;
          display: flex;
          align-items: center;

          .btn {
            cursor: pointer;
          }
        }
      }
    }
  }

  ::v-deep .pagination {
    width: 100%;
    padding: 8px 0;
    line-height: 28px;
    background: #fff;
  }

  ::v-deep .el-pager li,
  ::v-deep .el-pagination button {
    min-width: 28px;
    background: none !important;
  }
}

.course-item {
  display: flex;
  padding: 30px 20px;
  cursor: pointer;
  align-items: center;
  border-top: 1px solid #f5f5f5;

  &.selected {
    background: #ededed;
  }

  &.course-item2 {
    padding-bottom: 0;

    .cover {
      width: 120px;
      height: 68px;
      
      .tip {
        padding: 6px 4px;
        font-size: 12px;
        color: #ffffff;
        line-height: 12px;
        position: absolute;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.59);
        border-radius: 4px;
      }
    }

    .info {
      .title {
        white-space: normal;
      }
    }
  }

  &:first-child {
    border-top: 0;
  }

  &:hover {
    // background: #f0f0f0;
  }

  .cover {
    width: 80px;
    height: 45px;
    position: relative;
    background: #d8d8d8;
    border-radius: 2px;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }

    .status {
      right: 0;
      bottom: 0;
      position: absolute;
      width: 45px;
      font-size: 12px;
      color: #ffffff;
      line-height: 19px;
      text-align: center;
      background: rgba(0, 0, 0, 0.7);
    }
  }

  .info {
    flex: 1;
    overflow: hidden;
    margin-left: 14px;

    .title {
      font-size: 14px;
      color: #313131;
      word-break: break-all;
    }

    .time {
      font-size: 12px;
      color: #868686;
      margin-top: 20px;
    }
  }
}

::v-deep .empty-contain {
  margin-top: 94px;

  .img {
    display: block;
    margin: 0 auto;
  }

  .text {
    font-size: 14px;
    color: #666666;
    text-align: center;
    margin-top: 20px;
  }
}
</style>
